import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import CookieConsent from 'react-cookie-consent'
import { AnimatePresence, motion } from 'framer-motion'
import Theme from '../../styles/Theme'
import CustomLink from './CustomLink'
import Footer from './Footer'
import Header from './Header'
import 'bootstrap/dist/css/bootstrap.css'
import '../../styles/all.css'
import ClosedModal from '../elements/ClosedModal'

const ToTop = styled(motion.button)`
  position: fixed;
  right: 0;
  border-radius: 0.5rem 0 0 0.5rem;
  top: 75%;
  background: #d50032;
  color: #fff;
  z-index: 1000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
`

const ToTopSvg = styled.svg``

const Content = styled.div`
  @media (min-width: 992px) {
    padding-top: ${(props) => (props.isSticky ? 65 : 145)}px;
  }

  @media (max-width: 991px) {
    padding-top: 65px;
  }
`

function Layout({ children, subFooter }) {
  const [isSticky, setSticky] = useState(false)
  const [showToTop, setShowToTop] = useState(false)

  const handleScroll = () => {
    setSticky(window.scrollY > 0)
    setShowToTop(window.scrollY > 100)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [handleScroll])

  return (
    <Theme>
      <Header isSticky={isSticky} />
      <Content isSticky={isSticky}>{children}</Content>
      <Footer subFooter={subFooter} />

      <CookieConsent
        location="bottom"
        style={{ backgroundColor: '#D50032', color: '#FFFFFF' }}
        buttonText="Akkoord"
      >
        <div className="px-lg-5">
          <div>
            We gebruiken cookies om ervoor te zorgen dat onze website zo soepel
            mogelijk draait. Als u doorgaat met het gebruiken van de website,
            gaan we er vanuit dat u ermee instemt.{' '}
            <CustomLink to="/privacyverklaring">
              Lees hier de privacyverklaring
            </CustomLink>
          </div>
        </div>
      </CookieConsent>

      <AnimatePresence>
        {showToTop && (
          <ToTop
            type="button"
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              })
            }}
            animate={{
              opacity: 1,
              x: 0,
            }}
            initial={{
              opacity: 0,
              x: 100,
            }}
            exit={{
              opacity: 0,
              x: 100,
            }}
            transition={{
              duration: 0.2,
            }}
            className="px-2 py-1 px-lg-3 py-lg-2"
          >
            <ToTopSvg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lg:w-6 lg:h-6 h-5 w-5"
            >
              <line x1="12" y1="19" x2="12" y2="5" />
              <polyline points="5 12 12 5 19 12" />
            </ToTopSvg>
          </ToTop>
        )}
      </AnimatePresence>
      {/* <ClosedModal /> */}
    </Theme>
  )
}

export default Layout
